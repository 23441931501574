@media (min-width: 768px) {
#release {
        display: flex;
        flex-direction: row;
        width:100%;
    }
    #release-info {
        width:50%;
        padding-left:6px;
    }
    #release-cover {
        padding-right:6px;
        width:50%;
    }
    #index div {
        width:calc(33% - 16px);
    }
}