@import "colors";
#release {
    padding-top:40px;
}

#release-links img {
    width: 44px;
    height: 44px;
}
#release-links h4 {
    margin-bottom: 8px;
}
#release-links ul {
    padding:0;
    margin:0;
    margin-top:12px;
}
#release-links li {
    padding:0;
    margin:0;
    background-color: $white;
    list-style-type: none;
    width: 100%;
    text-align: center;
    color: $black;
    height:44px;
    padding:6px;
    line-height:32px;
    margin-bottom: 3px;
}
#release-links li img {
    width: 32px;
    height:32px;
    margin-right: 12px;
}
#release-links li a {
    color: $black;
    display: flex;
    flex-direction: row;
}

#release-cover img {
    width: 100%;
}

#release-links, #release-tracklist, #release-credits {
    margin-top: 32px;
}

#release-tracklist ol {
    margin:0;
    padding:0;
    padding-left: 32px;
    margin-top:8px;
}
#release-tracklist li {
    background-color: $black;
    padding-left: 0px;
}

#release-credits ul {
    margin-top: 8px;
    padding-left: 0px;
}
#release-credits li {
    padding-left: 0px;
    list-style-type: none;
}