.index-album img {
    width: 100%;
    height: auto;
    margin-bottom:6px;
}
.index-album {
    margin-bottom:64px;
    overflow-wrap: break-word;

}
h1,h2 {
    text-decoration: none;
}
.index-album a {
    text-decoration: none;
}
#index {
    padding-top: 32px;
}
#index h2,#index h3 {
    font-size: 14px;
}
#index {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}
#index div {
    width:calc(50% - 16px);
}