@import "colors";
header {
    width: 100%;
    overflow: hidden;
    background-color: $white;
    height: 56px;
    color: black;
    flex-shrink: 0;
}
header a, header a:visited {
    color: $black;
}
header li, header ul {
    margin: 0;
    padding:0;
    display: inline;
    list-style-type: none;
}
header li {
    line-height: 56px;
    color: black;
}
header img {
    width: 44px;
    height: 48px;
    // filter: invert(1);
    padding-top: 8px;
}
#header-content {
    display: flex;
    flex-direction: row;
}
#header-content div {
    flex-grow: 2;
}
#logo {
    margin-left:-6px;
}