@import "colors";

* {box-sizing: border-box;}
@font-face
{
    font-family: 'bios';
    src: url("/assets/fonts/Web437_IBM_BIOS.woff") format('woff');
    font-weight: 100;
}
@font-face
{
    font-family: 'bios-x2';
    src: url("/assets/fonts/Web437_IBM_BIOS-2y.woff") format('woff');
    font-weight: 100;
}
@font-face
{
    font-family: 'nitti';
    src: url("/assets/fonts/nitti-webfont.woff") format('woff');
    font-weight: 100;
}
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons'),
      local('MaterialIcons-Regular'),
      url(/assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

html, body {
    height: 100%;
    max-width: 100%;
}
body {
    background-color:$black;
    padding:0;
    margin:0;
    font-family: 'nitti';
    color: $gray;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}
body,h1,h2,h3,h4,h5,h6 {
    font-size: 16px;
    margin:0;
    padding:0;
}
h1,h2,h3,h4,h5,h6 {
    // font-family: 'bios-x2';
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $white;
}
h1 {
    font-size: 36px;
    line-height: 31px;
}
a {
    color:$green;
}
.content {
    padding: 0 24px;
    max-width:900px;
    margin: 0 auto;
}
#release img {
    width: 100%;
    height: auto;
    
}
main {
    flex: 1 0 auto;
    width:100%;
    max-width: 100%;
  }
  footer {
    flex-shrink: 0;
  }
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';
  }