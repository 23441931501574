#links {
    padding-top: 32px;
}
.links-album {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
}
.links-album img {
    width: 44px;
    height: 44px;
    margin-right: 8px;
}